<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import axios from 'axios'
let _logout = false
import VueCookies from "vue-cookies"

export default {
  name: 'App',
  components: {
    
  },
  async created() {
    axios.interceptors.response.use( (response) =>{
      _logout = false
    return response
    }, async (err)=>{
      // console.log("aqui",err.response)
      if (err.response && err.response.status == 401){
        //await this.$store.dispatch("auth/login", this.$route.params.id)
        this.$router.push('/site/error')
      }
      return Promise.reject(err)
    })
  }
}
</script>

<style lang="scss">

@import 'design/index.scss';
@import 'design/bancos/icones-bancos-brasileiros.css';

@font-face {
  font-family: 'Berlin Sans FB Local';
  src:  url('design/fonts/BerlinSansFBBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

.month-picker__month.selected{
  background-color: $color-success !important;
  box-shadow: inset 0 0 3px $color-success, 0px 2px 5px rgb(85 176 242 / 20%) !important;
}
.month-picker__container{
  width: 372px !important;
}
</style>
