import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  // if (to.matched.some(record => record.meta.requiresAuth)) {

    
  //   if (store.getters['auth/isLoggedIn'])
  //     return next()
  //   else
  //     return next('/site/error')
  // } else
  //   return next()

  return next()
})

export default router