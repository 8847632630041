export default [ 
    {
        path: '/site',
        name: 'Site',
        component: () => import('../views/Site/SiteLayout.vue'),
        meta: { 
            requiresAuth: false,
        },
        children : [          
            {
                path: '/site/error',
                name: 'Error',
                component: () => import('../components/Site/Error/index.vue'),
                meta: { 
                    requiresAuth: false,
                },
            },
            {
                path: '/site/checkout-payment/:id',
                name: 'Sacola de Compra',
                component: () => import('../components/Site/SacolaCompra/index.vue'),
                meta: { 
                    requiresAuth: false,
                },
            },
        ]
    },
    {
        path: '/site/payment-direct',
        name: 'Payment Direct',
        component: () => import('../components/Site/PaymentDirect/index.vue'),
        meta: { 
            requiresAuth: false,
        },
    },
    {
        path: '*',
        redirect: '/site/error'
    }
]