import DefaultUtils from '../../utils/default.utils'
import ApiService from '../../services/api.service'
import VueCookies from "vue-cookies"
import jwtDecode from 'jwt-decode'

const cookieTokenName = 'access_token'

const _token = DefaultUtils.base64(DefaultUtils.getCookie(cookieTokenName))
const _tokensExpiry = DefaultUtils.base64(DefaultUtils.getCookie("tokensExpiry")) 

const state = {
    token: _token || "",
    status: null,
    tokensExpiry: _tokensExpiry || null,
}

// getters
const getters = {
    isLoggedIn: state => !!state.token,
    getToken: state => state.token,
    authStatus: state => state.status,
    tokensExpiry: state => state.tokensExpiry
}

// actions
const actions = {

    async login({ commit }, model) {
        const body = {
            Id: model
        }
        let agora = new Date()
        let expires = Date(state.tokensExpiry)

        if(agora > expires || state.tokensExpiry == null){

            const resp = await ApiService.chamaApi("api/access/login-cliente", body)

            if (resp.Sucesso) {
                commit("auth_success", resp)
                return resp
            } else {
                commit("auth_error")
            }
            return resp
        }

        return {
            Sucesso: true
        }
    },
    logout({ commit }) {
        commit("logout")
    },
}

// mutations
const mutations = {

    auth_request(state) {
        state.status = "loading"
    },
    auth_success(state, resp) {
        const user = jwtDecode(resp.Token)

        state.status = "success"
        state.token = resp.Token
        state.tokensExpiry = user._expires
        state.id_transation = user.Name

        VueCookies.set("tokensExpiry", state.tokensExpiry, user.expires[0], null)
        VueCookies.set(cookieTokenName, resp.Token, user.expires[0], null)
    },
    auth_error(state) {
        state.status = "error"
        state.token = null
        state.tokensExpiry = null

        VueCookies.remove(cookieTokenName)
        VueCookies.remove("tokensExpiry")
    },
    logout(state) {
        state.status = null
        state.token = null
        state.tokensExpiry = null

        VueCookies.remove(cookieTokenName)
        VueCookies.remove("tokensExpiry")
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}